import Nav from '../components/Nav.js';
import Footer from '../components/Footer.js';
import Pricing from '../components/Pricing.js';
import { useEffect} from 'react';

function ContactScreen() {


    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
    <div className="ContactScreen" id="contact1">
        <Nav />
        <div className='contact-container'>
        <div className="contactScreen-info">
                <h1 style={{marginBottom: 0}}>Contact Us</h1>
                <p style={{fontSize: 14}}>Interested in me? Send me an email, and i'll be sure to reply!</p>
                <h2>Email</h2>
                <p style={{fontSize: 12.5}} className="contactScreen-email">marseywebdev@gmail.com</p>
          
            </div>

            <div className="contactScreen-btns">
                <form name='contact' method='post' data-netlify='true' className="form">
                <input type="hidden" name="form-name" value="contact" />
                    <input className="input" name='name' type='text' placeholder="Name" required></input>
                    <input className="input" name='email' type='email' placeholder="Email" required></input>
                    <input className="input" name='tel' type='tel' placeholder="Phone"></input>
                    <textarea name='message' className="input2"type='text' placeholder="Your message here"></textarea>              
                    <button className="contact-submit" type='submit'>Submit</button>
                </form>
            </div>
        </div>
       <Pricing />
       <Footer />

        </div>
    )


}

export default ContactScreen;
import Footer from '../components/Footer.js';
import Nav from '../components/Nav.js';
import logo from '../images/MarsyWebdev.svg';

function AboutScreen() {
    return (
    <section className="AboutScreen" id="about1">
        <Nav />
        <div className='aboutScreen-main'>
        <img decoding='async' src={logo} alt='logo'></img>
        <div className='about-container'>
            <div className='about-1'>
            <p className='about-title'>My Story</p>
             <p className='about-text'>A few years ago, I had this idea to create a website but I was completely clueless when it came to web development. I decided to give Wordpress a try and ended up shelling out over $200 for little add-ons that turned out to be useless.</p>
             <p className='about-text'> When I finally got my website, I realized it was a lot harder to design than I thought. I had no idea where to put white space, what color combinations to use, or how to organize my content.</p>
             <p className='about-text'>And don't even get me started on making the site work on both mobile and desktop. It was a nightmare! But instead of giving up, I decided to develop my skills and create a real website that I could be proud of by hand coding it from scratch instead of using page builders.</p>
             <p className='about-text'>I began a quest of learning HTML then the nightmare that was learing css for the first time, and then Javascript. All of which I gained great familiarity with and developed many projects using those technologies. After a while I learned React, then dabbled in express and postgres to create full stack applications.</p>
             <p className='about-text'>It is now that i want to seek out a greater goal of not only making projects for myself that I can sit around and be proud of, but helping build projects that will be used in the real world by real people! I am very motivated to do great at whatever I do and will always give my all. Thank you for reading this far that is very appreciated.</p>
            </div>
        </div>
       
        
      </div>

      <Footer />

    </section>
    )


}

export default AboutScreen;
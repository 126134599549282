import { Link } from "react-router-dom";
import Nav from "../components/Nav";
import Design from '../images/Design.jpg';
import DesignOverlay from '../images/Design-overlay.svg';
import CardIcon from '../images/card-icon.svg';
import CardIcon2 from '../images/card-icon2.svg';
import CardIcon3 from '../images/card-icon3.svg';
import Footer from "../components/Footer";
import Design2 from '../images/design2.svg';
import Pricing from "../components/Pricing";

function HomeScreen() {
    return (
    <div className="HomeScreen" id="HomeScreen1">
        <Nav />
        <section className="main">
            <h1>Jullian Blanco - Web Developer</h1>
            <div className="main-info">
             <img decoding="async" src={Design} alt='computer'/>
             <h2>Web Developer specializing in React and front end development - Also familiar with postgres and express <span><a href='/about'> about me.</a></span></h2>
            </div>
           <Link to='/contact#contact1'> <button>Contact Me</button></Link>
        </section>

        <section className="info">
            <figure className="info-header">
                <img decoding="async" src={DesignOverlay} alt='computer'/>
                <p>Skills</p>
            </figure>

            <div className="cards-container">
             <div className="cards">
                <img decoding="async" loading="lazy" src={CardIcon} alt='icon'></img>
                <p className="card-title">Javascript</p>
                 <p className="card-text">Most of my work I have done has been with javascript so I feel very familiar with it, this website as well as all the others I have created have been with Javascript. It is my favorite language!</p>
             </div>

              <div className="cards">
                <img decoding="async" loading="lazy" src={CardIcon} alt='icon'></img>
                <p className="card-title">React</p>
                <p className="card-text">Like Javascript I am familiar with react as well, this website was again created using React. I also have a mini mock shoe ecommerce site that really shows off React in the projects section. </p>
              </div>

              <div className="cards">
                <img decoding="async" loading="lazy" src={CardIcon} alt='icon'></img>
                <p className="card-title">Express/postgres</p>
                <p className="card-text">I built the authentication for my shoe site using express and postgres, although the server is not yet deployed so it is currently only functional on my computer for now. </p>
              </div>

            </div>
        </section>

    

        <section className="offers-section">
            <p className="offers-header">Some tools used in my sites</p>
            <div className="offers-info-container">
             <div className="offers-column1">
             <div className="offers-info">
               <img decoding="async" loading="lazy" src={CardIcon2} alt='icon'/>
                <div className="offers">
                    <p className="offers-title">react-router-hash-link</p>
                    <p className="offers-text">This site uses react-router-hash-link for it's navigation functionality.</p>
                </div>
             </div>

             <div className="offers-info">
               <img decoding="async" loading="lazy" src={CardIcon2} alt='icon'/>
                <div className="offers">
                    <p className="offers-title">useState</p>
                    <p className="offers-text">This site uses useState to add mobile responsiveness to the navigation.</p>
                </div>
             </div>

             <div className="offers-info">
               <img decoding="async" loading="lazy" src={CardIcon2} alt='icon'/>
                <div className="offers">
                    <p className="offers-title">useEffect</p>
                    <p className="offers-text">On my shoe site I use useEffect to always allow the page to load at the top of the screen when a user navigates to a new page. </p>
                </div>
             </div>

             <div className="offers-info">
               <img decoding="async" loading="lazy" src={CardIcon2} alt='icon'/>
                <div className="offers">
                    <p className="offers-title">useNavigate</p>
                    <p className="offers-text">On my shoe site I use useNavigate to navigate users to different pages. </p>
                </div>
             </div>

             </div>

             <div className="offers-column2">
             <div className="offers-info">
               <img decoding="async" loading="lazy" src={CardIcon3} alt='icon'/>
                <div className="offers">
                    <p className="offers-title">useLocation</p>
                    <p className="offers-text">On my shoe site I use useLocation to gather information about the product the user chooses to carry to their cart.</p>
                </div>
             </div>

             <div className="offers-info">
               <img decoding="async" loading="lazy" src={CardIcon3} alt='icon'/>
                <div className="offers">
                    <p className="offers-title">Fetch</p>
                    <p className="offers-text">On my shoe site I used fetch to send requests to my backend for authentication.</p>
                </div>
             </div>

             <div className="offers-info">
               <img decoding="async" loading="lazy" src={CardIcon3} alt='icon'/>
                <div className="offers">
                    <p className="offers-title">.map</p>
                    <p className="offers-text">On my shoe site I used map to display all the products and their information contained in my data file.</p>
                </div>
             </div>

             <div className="offers-info">
               <img decoding="async" loading="lazy" src={CardIcon3} alt='icon'/>
                <div className="offers">
                    <p className="offers-title">Bcrypt</p>
                    <p className="offers-text">In my shoe site I used bcrypt to help hash the passwords and make the database more secure. </p>
                </div>
             </div>
             </div>
             <img decoding="async" loading="lazy" className="offers-main-img" src={Design2} alt='computer'/>
            </div>

            
        </section>
        
        <Pricing/>
        <Footer />


    </div>
    )


}

export default HomeScreen;
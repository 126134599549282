import logo from '../images/MarsyWebdev.svg';

function Footer() {
    return (
        <section className='footer'>
            <div className="footer-main">
                <div className="footer-logo">
                    <img decoding='async' loading='lazy' src={logo} alt='logo'/>
                    <p>Quality, Focused, Professional. </p>
                </div>

                <div className='footer-container'>

                <div className="footer-item">
                    <p className='footer-title'>Services</p>
                    <p className='footer-text'>Web Design</p>
                    <p className='footer-text'>Web Development</p>
                    <p className='footer-text'>Content Creation</p>
                    <p className='footer-text'>SEO Management</p>
                </div>

                <div className="footer-item">
                    <p className='footer-title'>Contact</p>
                    <p className='footer-text'>marseywebdev@gmail.com</p>
                </div>


                </div>

              

            </div>

            <div className='footer-bottom'>
                <p> 2023 MarseyWebdev</p>

            </div>


        </section>
       
    )
   
}

export default Footer;
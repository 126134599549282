import Nav from '../components/Nav.js';
import Footer from '../components/Footer.js';
import Pricing from '../components/Pricing.js';

function ServicesScreen() {
    return (
    <section className="ServicesScreen" id="services1">
        <Nav />
        <h1>Our Services</h1>

    <div className='services-container'>
        <div className='Services-main'>
            <p className='services-title'>Web Design</p>
            <p className='services-text'>
            As web designers, all of our packages include custom web designs tailored to your business. We specialize in designing modern, user-friendly, and mobile-responsive websites that are tailored to your unique brand.  By using Figma, We are able to create high-quality designs that allow you to visualize and interact with your website before it is built.
            </p>
        </div>

        <div className='Services-main'>
            <p className='services-title'>Web Development</p>
            <p className='services-text'>
            At MarseyWebdev we create modern, user-friendly, and mobile-responsive websites that are optimized for speed, search engine optimization, and user experience. We are not only experienced in using the latest web development technologies and frameworks such as ReactJS, but also keep up-to-date with the latest trends and techniques in web development, which means that your website will be built using the latest industry best practices.
            </p>
        </div>

        <div className='Services-main'>
            <p className='services-title'>Content Creation</p>
            <p className='services-text'>
            As a business you not only need to have a website that looks great, but also one that conveys your message effectively to your audience. Doing this takes time and energy that we know could be better spent focusing on your business, which is why we write out everything for you. No banging your head thinking of what to write, Finding the right fonts and font sizes, looking over every bit of text for spelling errors, leave it all to us.
            </p>
        </div>

        <div className='Services-main'>
            <p className='services-title'>SEO management</p>
            <p className='services-text'>
            Are you struggling to get your website to rank higher on search engines? We're here to help you not only build a beautiful and user-friendly website, but also optimize it for search engines. With our SEO services, We will strive to ensure that your website is designed with the right keywords, meta tags, and descriptions, making it more visible to potential customers who are searching for your products or services. Don't let your website get drowned in the sea of every other business making a website.
            </p>
        </div>

    </div>
        
        <Pricing />
        <Footer />
        
    </section>
    )


}

export default ServicesScreen;
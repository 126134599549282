import Nav from '../components/Nav.js';
import project from '../images/GrillednGreeted.svg';
import Footer from '../components/Footer.js';
import Pricing from '../components/Pricing.js';
import shoe from '../images/design2.jpg';

function OurWorkScreen() {
    return (
    <div className="OurWorkScreen" id="OurWork1">
        <Nav />
        <div className='workScreen-header'>
            <h1>My Work</h1>
            <h2> Custom hand-coded websites. </h2>
        </div>

        <div className='workScreen-main'>
            <a href='https://shoestoreproject.netlify.app/'><img src={shoe} alt='demo store'/></a>
            
            <div>

            </div>
            <p className='work-title'>Shoe Project</p>
            <p className='work-text'> Hand-coded e-commerce site with cart functionality and authentication.</p>
        </div>

        <div className='workScreen-main'>
            <a href='https://www.grilledngreeted.com'><img src={project} alt='resturant'/></a>
            
            <div>

            </div>
            <p className='work-title'>Grilled n Greeted</p>
            <p className='work-text'> Hand-coded 4 page resturant website with custom responsive design.</p>
        </div>

     
        <Pricing />
        <Footer />
        
    </div>
    )


}

export default OurWorkScreen;



function Pricing() {

    return(
        <section className="pricing">
        <p className="pricing-header"><span>Straightforward</span> Pricing Options</p>
        <p className="pricing-description">If you are interested in hiring me for positions relating to web development or have any questions you would like to ask please contact me at marseywebdev@gmail.com!</p>

        <div className="options-container">
            <div className="Options">
                <p className="level">Entry Dev</p>
                <p className="price">$Hire</p>
                <p className="price-text">1 Web Developer ready to help work on your projects and company! </p>
                <p className="price-text">Motivated</p>
                <p className="price-text">Familar with various technologies</p>
                <p className="price-text">Knowledgeable about web development</p>
                <div className="black-space"></div>
            </div>

            <div className="Options">
                <p className="level">Standard Dev</p>
                <p className="price">$Hire</p>
                <p className="price-text">1 Web Developer excited to help work on your projects and company! </p>
                <p className="price-text">Motivated</p>
                <p className="price-text">Familar with various technologies</p>
                <p className="price-text">Knowledgeable about web development</p>
                <div className="black-space"></div>
            </div>

            <div className="Options">
                <p className="level">Premium Dev</p>
                <p className="price">$Hire</p>
                <p className="price-text">1 Web Developer excited to help work on your projects and company! </p>
                <p className="price-text">Motivated</p>
                <p className="price-text">Familar with various technologies</p>
                <p className="price-text">Knowledgeable about web development</p>
                <div className="black-space"></div>
            </div>

        </div>

</section>
    )
   
}

export default Pricing;
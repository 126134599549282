import './App.css';
import { ReactDOM } from 'react';
import { Route, Routes } from 'react-router-dom';
import ContactScreen from './screens/ContactScreen';
import HomeScreen from './screens/HomeScreen';
import OurWorkScreen from './screens/OurWorkScreen';
import AboutScreen from './screens/AboutScreen';
import ServicesScreen from './screens/ServicesScreen';

function App() {
  return (
<div className='App'>
  <Routes>
   <Route path='/' element={<HomeScreen/>}></Route>
   <Route path='/contact' element={<ContactScreen/>}></Route>
   <Route path='/services' element={<ServicesScreen/>}></Route>
   <Route path='/about' element={<AboutScreen/>}></Route>
   <Route path='/ourwork' element={<OurWorkScreen/>}></Route>
  </Routes>

 
</div>
  );
}

export default App;